<template>
  <div class="dashboard">
    <div class="container">
      <base-heading class="dashboard-heading" bold="bold">
        {{ $t("nav.dashboard") }}
      </base-heading>

      <div class="dashboard__layout">
        <div class="dashboard__left">
          <dashboard-heading
            :userType="userType"
            class="dashboard__left-heading"
          />
          <dashboard-banner :userType="userType" />
        </div>

        <template v-if="userType === 'standard'">
          <div class="dashboard__quote">
            <p class="dashboard__quote-mark">
              “
            </p>
            <p class="dashboard__quote-content">
              If you know you can do better...<br />
              then do better
            </p>
          </div>
          <base-button
            type="outline-reverse"
            @click="videoStatus = true"
          >
            {{ $t("dashboard.author.demoVideo") }}
          </base-button>
          <base-button
            type="outline-reverse"
            href="/ebook.pdf"
            to="/ebook.pdf"
            nativeLink
            :title="$t('ebook')"
            :aria-label="$t('ebook')"
            target="_blank"
            rel="noopener noreferrer"
            @click="openEbook"
          >
            {{ $t("ebook") }}
          </base-button>
        </template>
        <template v-if="userType === 'creator'">
          <author-activity :notifications="notifications" />
        </template>
      </div>

      <dashboard-stats
        v-if="userType === 'creator'"
        class="dashboard__stats"
        :orders="orders"
        :courses="courses"
        @open-ebook="openEbook"
        @watch-video="videoStatus = true"
      />
    </div>

    <base-modal
      v-model="videoStatus"
      size="big"
      :title="$t('nav.video')"
      :footer="false"
      frameless
      class="dashboard__modal"
      @input="setAsWatched"
    >
      <div slot="content" class="dashboard__modal-content">
        <base-video-player
          source="https://static.edunails.com/intro.mov"
          class="dashboard__modal-video"
        />
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import course from '@/api/services/course';
import auth from '@/api/services/auth';

export default {
  name: 'Dashboard',
  data: () => ({
    courses: [],
    notifications: [],
    orders: [],
    videoStatus: false,
    loading: false,
    skip: 0,
    limit: 10,
    total: 0,
  }),
  computed: {
    ...mapGetters({
      userType: 'auth/userType',
    }),
    ...mapState({
      hasWatchedInstructionalVideo: (state) => state.privacy.hasWatchedInstructionalVideo,
    }),
  },
  methods: {
    setAsWatched() {
      this.$store.dispatch('privacy/setInstructionalVideo', true);
    },
    setTutorialVideoModal() {
      if (!this.hasWatchedInstructionalVideo) {
        this.videoStatus = true;
      }
    },
    openEbook() {
      this.$store.dispatch('privacy/setEbookOpenStatus', true);
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const [courses, notifications, orders] = await Promise.all([
        course.getCreatorCourses(),
        auth.getUserNotifications(),
        course.getInstuctorSales({
          skip: this.skip,
          limit: this.limit,
        }),
      ]);

      this.courses = courses.data;
      this.notifications = notifications.data;
      this.orders = orders.data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);

      // Set video modal for instructors
      setTimeout(() => {
        this.setTutorialVideoModal();
      }, 1000);
    }
  },
};
</script>

<style lang="scss" src="./Dashboard.scss" scoped />
